import React from 'react';
import {Typography, Button, Dialog} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DataGrid from './utils/DataGrid'

const PlayerList = ({players, isOpen, handleClose, showOrganizationName=false}) => {

  const columns = [
    { key: "first_name", name: "First Name", editable: false },
    { key: "last_name", name: "Last Name", editable: false },
    { key: "email", name: "Email", editable: false }
  ]
  
  if (showOrganizationName) {
    columns.push({ key: "organization_name", name: "Organization", editable: false });
  }

  return ( 
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isOpen}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
      >
        <DialogContent style={{padding: 0}}>
          <Typography variant="h6" style={{padding: 10}}>There are {players.length} players in this organization</Typography>
          <DataGrid dataset={players} columns={columns} readonly={true} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default PlayerList