import React, {useState, useEffect} from 'react';
import classNames from "classnames";
import { AppBar, Toolbar, Typography, MenuItem, Select, Switch, FormControlLabel, Grow, Drawer, Button, Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ThemeProvider } from '@material-ui/styles';
import { withStyles, createMuiTheme } from "@material-ui/core/styles";
import { HighlightOff } from '@material-ui/icons';

import axios from "axios";
import _ from "lodash";

import CardAccessForm from "./CardAccessForm";
import CapitalSuit from "./CapitalSuit";
import CapitalCard from "./CapitalCard";
import Organizations from "./Organizations";
import Footer from "./Footer";
import ConfirmDialog from './utils/ConfirmDialog';
import { getResourceString } from "./utils/Strings"

import "../../assets/stylesheets/cards.scss";

const drawerWidth = 228;

const BIIMainLogo = require("../../assets/images/BIIMainlogo.png");
const defaultDeckIcon = require("../../assets/images/card_deck_icon.jpg");
const confirmLeaveGameDialogId = "leave-game-dialog-id";
const confirmDeleteSuitDialogId = "delete-suit-dialog-id";
const confirmDeleteCardDialogId = "delete-card-dialog-id";

// if allowLeaveGame is true, then players can click on the "Leave Game" button and return seat
const allowSeatReturn = false;

const styles = theme => ({
  button: {
    color: "white",
    backgroundColor: "#63544a",
    marginLeft: 5,
    marginRight: 5,
    fontWeight: "bold",
    '&:hover': {
      color: '#63544a',
    }
  },
  suitsContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    overflow: "auto",
    margin: 0,
    padding: 0,
    width: "100%",
    maxWidth: "100%",
    justifyContent: "space-evenly",
    justifyItems: "center",
    paddingLeft: "2vw",
    paddingRight: "2vw",
    height: `calc(100% - ${theme.spacing(14)}px)` // subtract tool bars and footer height
  },
  cardsContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    overflow: "auto",
    margin: 0,
    padding: 0,
    width: "100%",
    maxWidth: "100%",
    paddingLeft: "2vw",
    paddingRight: "2vw",
    height: `calc(100% - ${theme.spacing(20)}px)` // subtract tool bars and footer height
  },
  drawerPaper: {
    position: "fixed",
    width: drawerWidth,
    backgroundColor: "lightgrey",
    borderRadius: 0,
    borderTop: "none",
    borderBottom: "none",
    top: theme.spacing(8), // push content down to fix scrollbar position
    height: `calc(100% - ${theme.spacing(13)}px)` // subtract tool bars and footer height
  },
  drawerContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: 0
  },
  contentWrapper: {
    position: "fixed",
    borderRadius: 0,
    borderTop: "none",
    borderBottom: "none",
    top: theme.spacing(8), // push content down to fix scrollbar position
    width: `calc(100% - ${drawerWidth}px)`,
    height: `calc(100% - ${theme.spacing(0)}px)` // subtract tool bars and footer height
  },
  contentFull: {
    width: "100%",
    marginRight: 0
  },
  contentRight: {
    marginRight: drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  content: {
    textAlign: "center",
    fontSize: 22,
    fontFamily: "Montserrat",
    paddingTop: 20,
    paddingBottom: 0,
    padding: theme.spacing(3)
  },
  expandedSuit: {
    border: "1px solid black",
  }
});

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Montserrat'
  }
});

const CapitalCards = (props) => {
  const [lang, setLang] = useState("EN");
  const [allSuits, setAllSuits] = useState([]);
  const [allowedAccess, setAllowedAccess] = useState(false);
  const [expandedSuit, setExpandedSuit] = useState();
  const [showSelectedCards, setShowSelectedCards] = useState(true);
  const [showHand, setShowHand] = useState(false);
  const [selectedSuits, setSelectedSuits] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState();
  const [gameStarted, setGameStarted] = useState(false);
  const [player, setPlayer] = useState();
  const [organization, setOrganization] = useState();
  const [showInstructions, setShowInstructions] = useState(false);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [isAdminMode, setIsAdminMode] = useState(false);
  const [suitToDelete, setSuitToDelete] = useState();
  const [cardToDelete, setCardToDelete] = useState();
  const [openedConfirmDialogs, setOpenedConfirmDialogs] = useState([]);

  const organizations = props.organizations;

  useEffect(() => {
    setAllSuits(_.each(props.suits, (suit) => {
      suit.cards = _.filter(props.cards, card => card.suit_id === suit.id);
      _.map(suit.cards, card => card.suit = suit);
    }))
  }, [props.suits]);

  const shuffleCards = (array) => {
    const length = array.length;
    for (let i = length; i > 0; i--) {
      const randomIndex = Math.floor(Math.random() * i);
      const currentIndex = i - 1;
      const temp = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temp;
    }
    return array;
  };

  const handleLanguageSelect = (e) => {
    setLang(e.target.value);
  };

  const handleFullscreen = (suit) => {
    setExpandedSuit(suit);
  };

  const handleSelectSuit = (suit) => {
    const selected = isSuitSelected(suit);
    let _suits;
    if (selected) {
      const suits = _.clone(selectedSuits);
      _.remove(suits, s => s.id === suit.id)
      _suits = suits;
    } else {
      _suits = [...selectedSuits, suit];
    }
    setSelectedSuits(_suits);
  };

  const isSuitSelected = (suit) => {
    return suit && (_.find(selectedSuits, suit) !== undefined);
  }

  const handleSelectCard = (card) => {
    const selected = isCardSelected(card);
    let _cards;
    if (selected) {
      const cards = _.clone(selectedCards);
      _.remove(cards, c => c.id === card.id)
      _cards = cards;
    } else {
      _cards = [...selectedCards, card];
    }
    setSelectedCards(_cards);
  };

  const isCardSelected= (card) => {
    return card && (_.find(selectedCards, card) !== undefined);
  };

  const addCardToSelectedCards = () => {
    if (!isCardSelected(selectedCard)) {
      setSelectedCards([...selectedCards, selectedCard]);
    }
  };

  const removeCardFromSelectedCards = () => {
    if (isCardSelected(selectedCard)) {
      const cards = _.clone(selectedCards);
      _.remove(cards, c => c.id === selectedCard.id)
      setSelectedCards(cards);
    }
  };

  // required for onDrop() to be called
  const onDragOver = (e) => {
    e.preventDefault();    
  }

  const onDragEnd = (e) => {
    e.preventDefault();    
    setSelectedCard();
  }

  const setDragStartCard = (card) => {
    setSelectedCard(card);
  };

  const showHeader = () => {
    return <div className="game-tool-bar">
      <Select style={{marginLeft: "auto", color: "white"}} id="language-selector"
      value={lang}
        onChange={handleLanguageSelect}
        color="primary"
        label="Language"
      >
        <MenuItem value={"EN"}>English</MenuItem>
        <MenuItem value={"ES"}>Español</MenuItem>
      </Select>
      {
        !isAdminMode ?
          <div>
            <FormControlLabel style={{backgroundColor: "#63544a", paddingLeft: 5, paddingRight: 15, borderRadius: 6, marginLeft: 10, marginBottom: 0}}
              control={
                <Switch
                  checked={showHand}
                  onChange={handleShowHand}
                  color="default"
                  name="show-selected-cards" />
              }
              label={<Typography style={{fontSize: "0.875rem", fontWeight: "bold"}}>{_.toUpper(getResourceString(lang, "SHOW_HAND"))}</Typography>}
            />
            <Button variant="contained" className={classes.button}
              onClick={handleStartGame}>{!gameStarted ? getResourceString(lang, "START_GAME") : getResourceString(lang, "RESET_GAME")}
            </Button>
            <Button variant="contained" className={classes.button}
              onClick={handleLeaveGame}>{getResourceString(lang, "LEAVE_GAME")}
            </Button>
          </div> :
          <Button variant="contained" className={classes.button}
            onClick={handleSuitCreate}>{getResourceString(lang, "ADD_SUIT")}
          </Button>
        
      }
      <Typography variant="h6" style={{fontSize: "0.9rem", marginLeft: 20, border: "1px solid white", padding: 4, color: "white"}}>
        {allowedAccess && getResourceString(lang, "PLAYER_NAME") + `: ${_.get(player, "first_name", "")} ${_.get(player, "last_name", "")}`}
      </Typography>
      {
      isAdminUser && 
        <FormControlLabel style={{backgroundColor: "#63544a", paddingLeft: 5, paddingRight: 15, borderRadius: 6, marginLeft: 5, marginBottom: 0, marginRight: 0}}
          control={
            <Switch
              checked={isAdminMode}
              onChange={handleSetIsAdminMode}
              color="default"
              name="administration" />
          }
          label={<Typography style={{fontSize: "0.875rem", fontWeight: "bold"}}>{_.toUpper(getResourceString(lang, "ADMINISTRATION"))}</Typography>}
        />
      }
    </div>
}

  const showCardGame = () => {
    return showHand ? showCardsInHand() : (expandedSuit ? showExpandedCards() : showSuits());
  }

  const showAccessForm = () => {
    return <CardAccessForm
      handleSetAllowAccess={handleSetAllowAccess}
      handleJoinGame={handleJoinGame}
      organizations={organizations}
    />    
  };

  const showSuits = () => {
    const { classes } = props;

    // if game has been started, then only show suit in selectedSuits
    const suits = gameStarted ? selectedSuits : allSuits;

    return <div
        className={classNames(classes.contentWrapper, {
            [classes.contentShift]: showSelectedCards,
            [classes.contentRight]: showSelectedCards
        })}
      >
        <div className={classes.suitsContainer}>
        {
          suits.map((suit) => {
            return <CapitalSuit
              suit={suit}
              lang={lang}
              classes={classes}
              isAdminMode={isAdminMode}
              handleFullscreenClick={handleFullscreen}
              handleSuitUpdateClick={handleSuitUpdate}
              handleSuitUploadClick={handleSuitUpload}
              handleSuitDeleteClick={confirmSuitDelete}
              isSuitSelected={isSuitSelected}
              handleSelectSuit={handleSelectSuit}
              isCardSelected={isCardSelected}
              handleSelectCard={handleSelectCard}
            />
          })
        }
      </div>
    </div>
  };

  const handleHideInstructions = async () => {
    const payload = _.get(await axios.post("/api/players/update", {
      id: player.id,
      hide_help: !player.hide_help
    }), "data");

    setPlayer(payload);
    handeCloseInstructions();
  };

  const handeCloseInstructions = () => {
    setShowInstructions(false);
  };

  const showGameInstructions = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        aria-labelledby="customized-dialog-title"
        open={showInstructions}
        onClose={handeCloseInstructions} 
      >
        <DialogTitle id="customized-dialog-title" onClose={handeCloseInstructions}>
        {getResourceString(lang, "INSTRUCTIONS_TITLE")}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom variant="title">
            {getResourceString(lang, "INSTRUCTIONS_OVERVIEW_TITLE")}
          </Typography>
          <Typography gutterBottom>
            {getResourceString(lang, "INSTRUCTIONS_OVERVIEW_TEXT")}
          </Typography>
          <Typography gutterBottom variant="title">
            {getResourceString(lang, "INSTRUCTIONS_1_TITLE")}
          </Typography>
          <Typography gutterBottom>
            {getResourceString(lang, "INSTRUCTIONS_1_TEXT")}
          </Typography>
          <Typography gutterBottom variant="title">
            {getResourceString(lang, "INSTRUCTIONS_2_TITLE")}
          </Typography>
          <Typography gutterBottom>
            {getResourceString(lang, "INSTRUCTIONS_2_TEXT")}
          </Typography>
          <Typography gutterBottom variant="title">
            {getResourceString(lang, "INSTRUCTIONS_3_TITLE")}
          </Typography>
          <Typography gutterBottom>
            {getResourceString(lang, "INSTRUCTIONS_3_TEXT")}
          </Typography>
          <Typography gutterBottom variant="title">
            {getResourceString(lang, "INSTRUCTIONS_4_TITLE")}
          </Typography>
          <Typography gutterBottom>
            {getResourceString(lang, "INSTRUCTIONS_4_TEXT")}
          </Typography>
          <Typography gutterBottom variant="title">
            {getResourceString(lang, "INSTRUCTIONS_5_TITLE")}
          </Typography>
          <Typography gutterBottom>
            {getResourceString(lang, "INSTRUCTIONS_5_TEXT")}
          </Typography>
          <Typography gutterBottom variant="title">
            {getResourceString(lang, "INSTRUCTIONS_6_TITLE")}
          </Typography>
          <Typography gutterBottom>
            {getResourceString(lang, "INSTRUCTIONS_6_TEXT")}
          </Typography>
          <Typography gutterBottom variant="title">
            {getResourceString(lang, "INSTRUCTIONS_7_TITLE")}
          </Typography>
          <Typography gutterBottom>
            {getResourceString(lang, "INSTRUCTIONS_7_TEXT")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleHideInstructions} color="primary">
            {_.get(player, "hide_help", false) ? getResourceString(lang, "SHOW_INSTRUCTIONS") : getResourceString(lang, "HIDE_INSTRUCTIONS")}
          </Button>
          <Button autoFocus onClick={handeCloseInstructions} color="primary">
            {getResourceString(lang, "CLOSE")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const getSuitIcon = (suit) => {
    return(suit.icon ? `data:image/jpeg;base64,${suit.icon}` : defaultDeckIcon);
  };

  const showSuitImages = (expandedSuit) => {
    const suits = gameStarted ? selectedSuits : allSuits;

    return <div style={{display: "flex", alignItems: "center", justifyContent: "space-evenly", marginLeft: "auto"}}>
      {
        isAdminMode && <Button variant="contained" className={classes.button} style={{marginTop: 8, marginRight: 6, height: 38}} id="add-card" onClick={() => handleCardCreate(expandedSuit)}>
          {getResourceString(lang, "ADD_CARD")}
        </Button>
      }
      {
      suits.map(suit => {
        return <Tooltip title={lang === "EN" ? suit.name : suit.name_es} placement="start-top">
          <span>
            <img
              src={getSuitIcon(suit)}
              className={classNames("suit-icon")} 
              draggable="false"
              style={{cursor: "pointer", border: `1px solid ${suit === expandedSuit ? "black" : "lightgrey"}`}}
              onClick={() => handleFullscreen(suit)}
            />
          </span>
        </Tooltip>
      })
    }
    </div>
  };

  const showExpandedCards = () => {
    const { classes } = props;

    return <Grow
      in={expandedSuit}
      {...{ timeout: 1000 }}
    >
      <div
        className={classNames(classes.contentWrapper, {
            [classes.contentShift]: showSelectedCards,
            [classes.contentRight]: showSelectedCards
        })}
        onDrop={removeCardFromSelectedCards} onDragOver={onDragOver} onDragEnd={onDragEnd}
      >
        <div className={classes.content}
          style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
          {lang === 'EN' ? expandedSuit.name : expandedSuit.name_es}
          {
            showSuitImages(expandedSuit)
          }
          <Tooltip title={getResourceString(lang, "CLOSE")} placement="start-top">
            <HighlightOff
              style={{float: "right", marginTop: 8, marginLeft: 10, fontSize: 28, cursor: "pointer"}}
              id="fullscreen"
              onClick={() => handleFullscreen()}
            />
          </Tooltip>
        </div>
        <div className={classes.cardsContainer}>
            {
              expandedSuit.cards.map((card) => {
                return isCardSelected(card) ? null :
                <CapitalCard
                  card={card}
                  lang={lang}
                  classes={classes}
                  isAdminMode={isAdminMode}
                  isCardSelected={isCardSelected}
                  handleSelectCard={handleSelectCard}
                  handleCardUpdateClick={handleCardUpdate}
                  handleCardDeleteClick={confirmCardDelete}
                  setDragStartCard={setDragStartCard}
                />
              })
            }
        </div>
      </div>
    </Grow>
  };

  const showCardsInHand = () => {
    const { classes } = props;

    return <Grow
      in={showHand}
      {...{ timeout: 1000 }}
    >
      <div
        className={classNames(classes.contentWrapper, {
          [classes.contentShift]: showSelectedCards,
          [classes.contentFull]: showSelectedCards
        })}
      >
        <div className={classes.content}>
          {getResourceString(lang, "MY_HAND")}
        </div>
        <div className={classes.cardsContainer} style={{width: "100%"}}>
          {
            selectedCards.map((card) => {
              return <CapitalCard
                card={card}
                lang={lang}
                classes={classes}
                isAdminMode={isAdminMode}
                isCardSelected={isCardSelected}
                handleSelectCard={handleSelectCard}
                handleCardUpdateClick={handleCardUpdate}
                handleCardDeleteClick={confirmCardDelete}
                setDragStartCard={setDragStartCard}
              />
            })
          }
        </div>
      </div>
    </Grow>
  };

  const handleShowSelectedCards = () => {
    const { classes } = props;
    return <Drawer
          open={allowedAccess && (!showHand && showSelectedCards)}
          elevation={0}
          classes={{
            paper: classes.drawerPaper
          }}
          variant="persistent"
          anchor="right"
        >
        <div className={classes.drawerContent} onDrop={addCardToSelectedCards} onDragOver={onDragOver} onDragEnd={onDragEnd}>
          <Typography variant="h6" style={{marginTop: 5, textAlign: "center", color: "black"}}>
          {getResourceString(lang, "MY_HAND")}
          </Typography>
          {
            selectedCards.map((card) => {
              return <CapitalCard
                  card={card}
                  lang={lang}
                  classes={classes}
                  isAdminMode={isAdminMode}
                  isInDrawer={true}
                  isCardSelected={isCardSelected}
                  handleSelectCard={handleSelectCard}
                  handleCardUpdateClick={handleCardUpdate}
                  handleCardDeleteClick={confirmCardDelete}
                  setDragStartCard={setDragStartCard}
                />
            })
          }
        </div>
      </Drawer>
  };

  const handleSetIsAdminMode = () => {
    setIsAdminMode(!isAdminMode);
  };

  const handleShowHand = () => {
    setShowHand(!showHand);
  };

  const handleSetAllowAccess = (isAdmin) => {
    setAllowedAccess(true);
    setIsAdminUser(isAdmin);
  };

  /**
   * Returns true if player is added, false on error
   * @param {*} firstName 
   * @param {*} lastName 
   * @param {*} email 
   * @param {*} organization 
   * @param {*} accessCode 
   */
  const handleJoinGame = async (firstName, lastName, email, organization, accessCode) => {
    const organizationName = _.get(organization, "label");  // label from organization item in case it is manually entered
    const payload = await axios.post("/api/players/create", {
      first_name: firstName,
      last_name: lastName,
      email: email,
      organization_name: organizationName,
      access_code: accessCode
    });
    
    const player = _.get(payload, "data"); 
    if (!player) {
      return false;
    } else {
      setPlayer(player);
      setOrganization(organization);
      setShowInstructions(!player.hide_help);
      return true;
    }
  };

  const resetStates = () => {
    setExpandedSuit();
    setShowSelectedCards(true);
    setShowHand(false);
    setSelectedSuits([]);
    setSelectedCards([]);
};

  const handleStartGame = () => {
    const newState = !gameStarted;
    setGameStarted(newState);
    if (!newState) {
      resetStates();
    }
  };

  const handleLeaveGame = () => {
    const dialogs = _.clone(openedConfirmDialogs);
    dialogs.push(confirmLeaveGameDialogId);
    setOpenedConfirmDialogs(dialogs);
  }

  const leaveGame = async () => {
    if (allowSeatReturn && organization) {
      const url = "/api/players/leave";
      const payload =_.get(await axios.post(url, {
          organization_id: organization.id
      }), "data");  
    }

    setAllowedAccess(false);
    setGameStarted(false);
    resetStates();
  };

  const handleSetShowInstructions = (f) => setShowInstructions(f);
 
  const handleShowOrganizations = () => {
    const { classes } = props;

    return <Drawer
          open={allowedAccess && isAdminMode}
          elevation={0}
          classes={{
            paper: classes.drawerPaper
          }}
          variant="persistent"
          anchor="right"
        >
        <div className={classes.drawerContent}>
          <Organizations lang={lang} orgs={organizations}/>
        </div>
      </Drawer>
  };

  const handleSuitCreate = async () => {
    const suit = _.get(await axios.post("/api/suits/create",
    {
      name: getResourceString("EN", "NEW_SUIT_NAME"),
      name_es: getResourceString("ES", "NEW_SUIT_NAME"),
      def: getResourceString("EN", "NEW_SUIT_DEFINITION"),
      def_es: getResourceString("ES", "NEW_SUIT_DEFINITION"),
      color: "110, 87, 82"
    }), "data");

    // update allSuits array
    suit.cards = [];

    const suits = _.cloneDeep(allSuits);
    suits.push(suit)
    setAllSuits(suits);
  };

  const handleSuitUpdate = async (suit, nameField, suitName, definitionField, suitDefinition, suitColor) => {
    const payload = _.get(await axios.post("/api/suits/update", {
      id: suit.id,
      [nameField]: suitName,
      [definitionField]: suitDefinition,
      color: suitColor
    }), "data");

    // update allSuits array
    const suits = _.cloneDeep(allSuits);
    const _suit = _.find(suits, {id: suit.id});
    if (_suit) {
      _suit[nameField] = payload[nameField];
      _suit[definitionField] = payload[definitionField];
      _suit.color = payload.color;
    }
    setAllSuits(suits);
  };

  const confirmSuitDelete = (suit) => {
    setSuitToDelete(suit);
    const dialogs = _.clone(openedConfirmDialogs);
    dialogs.push(confirmDeleteSuitDialogId);
    setOpenedConfirmDialogs(dialogs);
  };

  const handleSuitDelete = async (suit) => {
    const payload = _.get(await axios.post("/api/suits/delete", {
      id: suit.id
    }), "data");

    // remove suit in suits array
    const suits = _.cloneDeep(allSuits);
    _.remove(suits, {id: suit.id});
    setAllSuits(suits);
  };

  const handleSuitUpload = async (formData) => {
    const payload = _.get(await axios.post("/api/suits/upload", formData), "data");

    // update allSuits array
    const suitId = parseInt(formData.get("suit_id"));
    const suits = _.cloneDeep(allSuits);
    const suit = _.find(suits, {id: suitId});
    suit.image = payload.image;
    suit.icon = payload.icon;
    suit.color = payload.color;
    setAllSuits(suits);
  };

  const handleCardCreate = async (suit) => {
    const payload = _.get(await axios.post("/api/cards/create", {
      name: getResourceString("EN", "NEW_CARD_NAME"),
      name_es: getResourceString("ES", "NEW_CARD_NAME"),
      def: getResourceString("EN", "NEW_CARD_DEFINITION"),
      def_es: getResourceString("ES", "NEW_CARD_DEFINITION"),
      suit_id: suit.id
    }), "data");

    // add card to suits array
    const suits = _.cloneDeep(allSuits);
    const _suit = _.find(suits, {id: suit.id});
    if (_suit) {
      payload.suit = _suit;
      _suit.cards.push(payload);
      setExpandedSuit(_suit);
    }
    setAllSuits(suits);
  };

  const handleCardUpdate = async (card, nameField, cardName, definitionField, cardDefinition) => {
    const payload = _.get(await axios.post("/api/cards/update", {
      id: card.id,
      [nameField]: cardName,
      [definitionField]: cardDefinition
    }), "data");

    // update card in suits array
    const suits = _.cloneDeep(allSuits);
    const suit = _.find(suits, {id: card.suit.id});
    if (suit) {
      const cards = _.cloneDeep(suit.cards);
      const oCard = _.find(cards, {id: card.id});
      if (oCard) {
        oCard[nameField] = payload[nameField];
        oCard[definitionField] = payload[definitionField];
      }
      suit.cards = cards;
    }
    setAllSuits(suits);
  };

  const confirmCardDelete = (card) => {
    setCardToDelete(card);
    const dialogs = _.clone(openedConfirmDialogs);
    dialogs.push(confirmDeleteCardDialogId);
    setOpenedConfirmDialogs(dialogs);
  };

  const handleCardDelete = async (card) => {
    const payload = _.get(await axios.post("/api/cards/delete", {
      id: card.id
    }), "data");

    // remove card in suits array
    const suits = _.cloneDeep(allSuits);
    const suit = _.find(suits, {id: card.suit.id});
    if (suit) {
      _.remove(suit.cards, {id: card.id});
    }
    setExpandedSuit(suit);
    setAllSuits(suits);
  };

  const isOpenConfirmDialog = (id) => {
    return _.includes(openedConfirmDialogs, id);
  };

  const closeConfirmDialog = (id) => {
    const dialogs = _.clone(openedConfirmDialogs);
    _.remove(dialogs, id);
    setOpenedConfirmDialogs([dialogs])
  };

  const { classes } = props;
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap" rel="stylesheet"/>
          <AppBar position="static">
          <Toolbar style={{paddingLeft: 2, backgroundColor: "#009eba"}}>
            <img src={BIIMainLogo} style={{height: 40, marginBottom: 8, paddingLeft: 10, paddingRight: 10}}/>
            <Typography variant="h5" style={{paddingLeft: 10, fontWeight: 600}}>
              {getResourceString(lang, "CARD_GAME_TITLE")}
            </Typography>
            {
              allowedAccess && showHeader()
            }
          </Toolbar>
        </AppBar>
        {isAdminMode ? handleShowOrganizations() : handleShowSelectedCards()}
        {showGameInstructions()}
        {allowedAccess ? showCardGame() : showAccessForm()}
        <ConfirmDialog
          dialogId={confirmLeaveGameDialogId}
          title={getResourceString(lang, "CONFIRM_LEAVE_GAME")}
          cancelLabel={getResourceString(lang, "CANCEL")}
          confirmLabel={getResourceString(lang, "CONTINUE")}
          message={getResourceString(lang, "LEAVE_GAME_MEASSAGE")}
          onSubmit={leaveGame}
          open={isOpenConfirmDialog}
          close={closeConfirmDialog}
          classes={classes}
        />
        <ConfirmDialog
          dialogId={confirmDeleteSuitDialogId}
          title={getResourceString(lang, "CONFIRM_DELETE_SUIT")}
          cancelLabel={getResourceString(lang, "CANCEL")}
          confirmLabel={getResourceString(lang, "CONTINUE")}
          message={getResourceString(lang, "DELETE_SUIT_MESSAGE")}
          onSubmit={handleSuitDelete}
          open={isOpenConfirmDialog}
          close={closeConfirmDialog}
          classes={classes}
          item={suitToDelete}
        />
        <ConfirmDialog
          dialogId={confirmDeleteCardDialogId}
          title={getResourceString(lang, "CONFIRM_DELETE_CARD")}
          cancelLabel={getResourceString(lang, "CANCEL")}
          confirmLabel={getResourceString(lang, "CONTINUE")}
          message={getResourceString(lang, "DELETE_CARD_MESSAGE")}
          onSubmit={handleCardDelete}
          open={isOpenConfirmDialog}
          close={closeConfirmDialog}
          classes={classes}
          item={cardToDelete}
        />
      </React.Fragment>
      <Footer lang={lang} allowedAccess={allowedAccess} handleSetShowInstructions={handleSetShowInstructions}/>
    </ThemeProvider>
  )
};

export default withStyles(styles, { withTheme: true })(CapitalCards);