import React, { useState } from "react";
import ReactDataGrid from "react-data-grid";
import { Button } from "@material-ui/core";
import _ from "lodash";

const DataGrid = ({dataset, columns, readonly, handleSaveRows}) => {
  const [rows, setRows] = useState(dataset);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  
const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const newRows = rows.slice();
    for (let i = fromRow; i <= toRow; i++) {
      newRows[i] = { ...newRows[i], ...updated };
    }
    setRows(newRows)
  };

  const selectRow = (rows) => {
    const indexes = _.clone(selectedIndexes);
    _.map(rows, row => {
      if(!_.includes(indexes, row.rowIdx)) {
        indexes.push(row.rowIdx);
      }
    });
    setSelectedIndexes(indexes);
  };

  const deselectRow = (rows) => {
    const indexes = _.clone(selectedIndexes);
    _.map(rows, row => {
      _.remove(indexes, r => r === row.rowIdx);
    })
    setSelectedIndexes(indexes)
  };

  const addRow = () => {
    const _rows = _.clone(rows);
    _rows.push({});
    setRows(_rows);
  };

  const deleteRows = () => {
    const _rows = _.clone(rows);
    const selectedRowIds = [];
    // get row ids from selectedRowIndexes, then remove from rows with ids
    _.map(selectedIndexes, index => {
        selectedRowIds.push(rows[index].id);
    });
    _.map(selectedRowIds, rowId => {
      _.remove(_rows, {id: rowId});
    });
    setRows(_rows);
    setSelectedIndexes([])
  };

  const resetRows = () => {
  };

  return (
    <React.Fragment>
        {
        !readonly &&
        <div style={{position: "relative"}}>
            <div style={{display: "flex", position: "absolute", top: -56, right: 0}}>
                <Button style={{color: "white", backgroundColor: "#0093B2", margin: 5}} onClick={addRow}>
                    Add
                </Button>
                <Button style={{color: "white", backgroundColor: "red", margin: 5}} onClick={deleteRows}>
                    Delete
                </Button>
                <Button style={{color: "white", backgroundColor: "#0093B2", margin: 5}} onClick={resetRows}>
                    Undo
                </Button>
                <Button style={{color: "white", backgroundColor: "#0093B2", margin: 5}} onClick={handleSaveRows}>
                    Save
                </Button>
            </div>
        </div>
        }
      <ReactDataGrid
        rowKey='id'
        columns={columns}
        rowGetter={i => rows[i]}
        rowsCount={rows.length}
        onGridRowsUpdated={onGridRowsUpdated}
        enableCellSelect={true}
        rowSelection={{
          showCheckbox: !readonly,
          enableShiftSelect: true,
          onRowsSelected: selectRow,
          onRowsDeselected: deselectRow,
          selectBy: {
              indexes: selectedIndexes
          }
        }}
      />
    </React.Fragment>
  );
};

export default DataGrid;