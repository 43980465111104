import React, { useRef, useState, useEffect } from 'react';
import classnames from "classnames";
import { IconButton, Typography, Checkbox, Tooltip } from "@material-ui/core";
import { UndoOutlined, SaveOutlined, DeleteOutlined } from '@material-ui/icons';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

import Editable from "./utils/Editable";
import { getResourceString } from "./utils/Strings"
import "../../assets/stylesheets/cards.scss";

const defaultEditDeckIcon = require("../../assets/images/card_deck_icon.jpg");

const CapitalCard = ({
  card, lang, isAdminMode, isInDrawer,
  handleCardUpdateClick, handleCardDeleteClick, setDragStartCard,
  isCardSelected, handleSelectCard
}) => {

  const cardNameRef = useRef();
  const cardDefinitionRef = useRef();

  const nameField = lang === "EN" ? "name" : `name_${_.lowerCase(lang)}`;
  const defField = lang === "EN" ? "def" : `def_${_.lowerCase(lang)}`;

  const [ cardName, setCardName ] = useState();
  const [ cardDefinition, setCardDefinition ] = useState();
  const [ isChanged, setIsChanged ] = useState(false);

  useEffect(() => {
    setCardName(_.get(card, nameField));
    setCardDefinition(_.get(card, defField));
  }, [lang, card]);

  // required for onDrop() to be called
  const onDragStart = (e) => {
    setDragStartCard(card);
  };

  const handleCardUpdate = () => {
    handleCardUpdateClick(card, nameField, cardName, defField, cardDefinition);
    setIsChanged(false);
  };

  const handleCardDelete = () => {
    handleCardDeleteClick(card);
  }

  const handleCardCancel = () => {
    setCardName(_.get(card, nameField));
    setCardDefinition(_.get(card, defField));
    setIsChanged(false);
  };

  const showCardContent = () => {
    return isAdminMode ? 
          <div style={{marginTop: 20, marginLeft: 10, marginRight: 10}}>
            <Editable
              className="card-label"
              text={cardName}
              placeholder="Card Name"
              childRef={cardNameRef}
              type="input"
            >
            <input
              ref={cardNameRef}
              type="text"
              name="name"
              className="card-label"
              style={{padding: 0, margin: 0}}
              placeholder="Card Name"
              value={cardName}
              onChange={e => {setCardName(e.target.value); setIsChanged(true);}}
            />
            </Editable>
            <Editable
              className="card-definition"
              text={cardDefinition}
              placeholder="Card Definition"
              childRef={cardDefinitionRef}
              type="textarea"
            >
              <textarea
                ref={cardDefinitionRef}
                style={{padding: 0, margin: 0, width: "100%", height: 176}}
                name="definition"
                placeholder="Card Definition"
                value={cardDefinition}
                onChange={e => {setCardDefinition(e.target.value); setIsChanged(true);}}
                />
            </Editable>
          </div>
        :
          <div style={{marginTop: 20, marginLeft: 10, marginRight: 10}}>
            <Typography className="card-label">
              {_.get(card, nameField)}
            </Typography>
            <Typography className="card-definition">
              {_.get(card, defField)}
            </Typography>
          </div>
  };

  const getSuitIcon = () => {
    return(card.suit.icon ? `data:image/jpeg;base64,${card.suit.icon}` : defaultEditDeckIcon);
  };

  return (
    <div className={classnames("card-container", { "card-container-main": !isInDrawer, "card-container-drawer": isInDrawer })} draggable="true" onDragStart={onDragStart}>
      <div className={classnames("card", "card-face", "card-front-face")} style={{border: `2px solid rgb(${card.suit.color})`}}>
        {
          showCardContent()
        }
      </div>
      {
        isAdminMode ?
          <div className="icon-bar" style={{marginTop: "-36px"}}>
            <div style={{width: "100%"}}>
              <Tooltip title={getResourceString(lang, "SAVE")} placement="start-top">
                <IconButton disabled={!isChanged} style={{float: "left", marginLeft: 10, fontSize: 40, padding: 0}} onClick={handleCardUpdate}>
                  <SaveOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title={getResourceString(lang, "DELETE")} placement="start-top">
                <IconButton disabled={!isChanged && !card.id}  style={{float: "left", marginLeft: 10, fontSize: 40, padding: 0}} onClick={handleCardDelete}>
                  <DeleteOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title={getResourceString(lang, "CANCEL")} placement="start-top">
                <IconButton disabled={!isChanged} style={{float: "right", marginRight: 10, fontSize: 40, padding: 0}} onClick={handleCardCancel}>
                  <UndoOutlined />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          :
          <div className="icon-bar">
            <div style={{width: "100%", zIndex: "9999"}}>
              <Tooltip title={isCardSelected(card) ? getResourceString(lang, "UNSELECT_CARD") : getResourceString(lang, "SELECT_CARD")} placement="start-top">
                <Checkbox
                  className="select-icon"
                  icon={<StarBorderIcon style={{transform: "scale(1.2)", color: "lightgrey"}}/>} checkedIcon={<StarIcon style={{color: "red"}}/>}
                  checked={isCardSelected(card)}
                  onChange={() => handleSelectCard(card)}
                  color="secondary"
                />
              </Tooltip>
              <img src={getSuitIcon()}  className="suit-icon" draggable="false" />
            </div>
          </div>
      }
    </div>
  )
};

export default CapitalCard;