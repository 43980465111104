const ResourceStrings = 
{
  "EN": {
    CARD_GAME_TITLE: "Integrated Capital Cards",
    START_GAME: "Start Game",
    RESET_GAME: "Reset Game",
    LEAVE_GAME: "Leave Game",
    PLAYER_NAME: "Player",
    CONTACT: "Contact",
    SUGGESTIONS: "Suggestions",
    COPYRIGHT: "Copyright",
    OPEN_SUIT: "Open suit",
    SELECT_SUIT: "Select suit",
    UNSELECT_SUIT: "Unselect suit",
    SELECT_CARD: "Select card",
    UNSELECT_CARD: "Unselect card",
    MY_HAND: "My Hand",
    SHOW_HAND: "Show Hand",
    CLOSE: "Close",
    CONFIRM_LEAVE_GAME: "Confirm Leave Game",
    LEAVE_GAME_MESSAGE: "Do you really want to leave the game?",
    INSTRUCTIONS: "Instructions",
    INSTRUCTIONS_TITLE: "Game Instructions",
    INSTRUCTIONS_OVERVIEW_TITLE: "How to Use the Online Cards",
    INSTRUCTIONS_OVERVIEW_TEXT: "The Boston Impact Initiative has created an Integrated Capital Card Deck that is designed to help community members, investors and funders learn how to deploy financial and social capital to close the racial wealth divide. The bilingual (English/Spanish) deck is made up of 121 cards in 8 suits that help match types of capital, transaction structures and impact criteria with startups, small businesses and nonprofits that are contributing to building regenerative and equitable local economies. In addition to serving as a glossary and flash cards, the deck can be used to engage participants in multiplayer games.",
    INSTRUCTIONS_1_TITLE: "Step 1: Select Language",
    INSTRUCTIONS_1_TEXT: "The cards can be viewed in either English or Spanish. Use the dropdown menu at the top to choose your preferred language.",
    INSTRUCTIONS_2_TITLE: "Step 2: View Suits",
    INSTRUCTIONS_2_TEXT: "There are eight suits in the card deck. You can read a definition of each suit on the back of the top card. To flip the card, simply click anywhere on the card. Click again to flip back.",
    INSTRUCTIONS_3_TITLE: "Step 3: Select Suits",
    INSTRUCTIONS_3_TEXT: "You may not need all the suits for each game. So to begin, select the suits you want to play with by clicking the star icon at the bottom left side of the card. Once you’ve selected the suits you want to play with, click “Start Game” on the top right-hand corner of your screen.",
    INSTRUCTIONS_4_TITLE: "Step 4: View Cards",
    INSTRUCTIONS_4_TEXT: "To view the cards in each suit, click the squares icon in the bottom right corner of the card. This will display all the cards in the suit. You can toggle between suits by selecting the suit icons to the upper right of the card display.",
    INSTRUCTIONS_5_TITLE: "Step 5: Select Cards",
    INSTRUCTIONS_5_TEXT: "Once you’ve identified the cards you want to put in your hand, drag each card to the “My Hand” panel on the right of your screen. You can select as many cards as the game allows. You can remove cards from your hand by dragging them back to the main window.",
    INSTRUCTIONS_6_TITLE: "Step 6: Show Hand",
    INSTRUCTIONS_6_TEXT: "Once you’re satisfied with the cards you’ve chosen, slide the “Show Hand” slider to the right. You’ll be able to view your hand in the main window. If you want to make additional changes to your hand, you can “Unselected Card” by clicking on the star icon in the left-hand corner of the card. Or you can slide the “Show Hand” slider to the left to return to viewing cards.",
    INSTRUCTIONS_7_TITLE: "Step 7: Start a New Game",
    INSTRUCTIONS_7_TEXT: "When the game is complete, select “Reset Game” to return to the beginning. ",
    HIDE_INSTRUCTIONS: "Don't show again",
    SHOW_INSTRUCTIONS: "Show again",
    ADMINISTRATION: "Admin Mode",
    ORGANIZATIONS: "Organizations",
    NEW_ORGANIZATION: "New Organization",
    EDIT_SUIT: "Edit Suit",
    SAVE: "Save",
    DELETE: "Delete",
    CANCEL: "Cancel",
    CONTINUE: "Continue",
    ADD_SUIT: "Add Suit",
    NEW_SUIT_NAME: "NEW SUIT",
    NEW_SUIT_DEFINITION: "Please enter new suit definition",
    CONFIRM_DELETE_SUIT: "Confirm Delete Suit",
    DELETE_SUIT_MESSAGE: "Do you really want to delete the suit?",
    ADD_CARD: "Add Card",
    NEW_CARD_NAME: "NEW CARD",
    NEW_CARD_DEFINITION: "Please enter new card definition",
    CONFIRM_DELETE_CARD: "Confirm Delete Card",
    DELETE_CARD_MESSAGE: "Do you really want to delete the card?",
  },
  "ES": {
    CARD_GAME_TITLE: "Baraja Capital Integrado",
    START_GAME: "Iniciar Juego",
    RESET_GAME: "Reiniciar Juego",
    LEAVE_GAME: "Dejar Juego",
    PLAYER_NAME: "Jugador",
    CONTACT: "Contacto",
    SUGGESTIONS: "Sugerencias",
    COPYRIGHT: "Todos los derechos reservados",
    OPEN_SUIT: "Abrir typo de carta",
    SELECT_SUIT: "Seleccionar typo de carta",
    UNSELECT_SUIT: "Descargar typo de carta",
    SELECT_CARD: "Seleccionar carta",
    UNSELECT_CARD: "Descargar carta",
    MY_HAND: "Mi Mano",
    SHOW_HAND: "Mostrar Mano",
    CLOSE: "Cerrar",
    CONFIRM_LEAVE_GAME: "Confirmar Dejar Juego",
    LEAVE_GAME_MESSAGE: "Do you really want to leave the game?",
    INSTRUCTIONS: "instrucciones",
    INSTRUCTIONS_TITLE: "Game Instructions",
    INSTRUCTIONS_OVERVIEW_TITLE: "How to Use the Online Cards",
    INSTRUCTIONS_OVERVIEW_TEXT: "The Boston Impact Initiative has created an Integrated Capital Card Deck that is designed to help community members, investors and funders learn how to deploy financial and social capital to close the racial wealth divide. The bilingual (English/Spanish) deck is made up of 121 cards in 8 suits that help match types of capital, transaction structures and impact criteria with startups, small businesses and nonprofits that are contributing to building regenerative and equitable local economies. In addition to serving as a glossary and flash cards, the deck can be used to engage participants in multiplayer games.",
    INSTRUCTIONS_1_TITLE: "Step 1: Select Language",
    INSTRUCTIONS_1_TEXT: "The cards can be viewed in either English or Spanish. Use the dropdown menu at the top to choose your preferred language.",
    INSTRUCTIONS_2_TITLE: "Step 2: View Suits",
    INSTRUCTIONS_2_TEXT: "There are eight suits in the card deck. You can read a definition of each suit on the back of the top card. To flip the card, simply click anywhere on the card. Click again to flip back.",
    INSTRUCTIONS_3_TITLE: "Step 3: Select Suits",
    INSTRUCTIONS_3_TEXT: "You may not need all the suits for each game. So to begin, select the suits you want to play with by clicking the star icon at the bottom left side of the card. Once you’ve selected the suits you want to play with, click “Start Game” on the top right-hand corner of your screen.",
    INSTRUCTIONS_4_TITLE: "Step 4: View Cards",
    INSTRUCTIONS_4_TEXT: "To view the cards in each suit, click the squares icon in the bottom right corner of the card. This will display all the cards in the suit. You can toggle between suits by selecting the suit icons to the upper right of the card display.",
    INSTRUCTIONS_5_TITLE: "Step 5: Select Cards",
    INSTRUCTIONS_5_TEXT: "Once you’ve identified the cards you want to put in your hand, drag each card to the “My Hand” panel on the right of your screen. You can select as many cards as the game allows. You can remove cards from your hand by dragging them back to the main window.",
    INSTRUCTIONS_6_TITLE: "Step 6: Show Hand",
    INSTRUCTIONS_6_TEXT: "Once you’re satisfied with the cards you’ve chosen, slide the “Show Hand” slider to the right. You’ll be able to view your hand in the main window. If you want to make additional changes to your hand, you can “Unselected Card” by clicking on the star icon in the left-hand corner of the card. Or you can slide the “Show Hand” slider to the left to return to viewing cards.",
    INSTRUCTIONS_7_TITLE: "Step 7: Start a New Game",
    INSTRUCTIONS_7_TEXT: "When the game is complete, select “Reset Game” to return to the beginning. ",
    HIDE_INSTRUCTIONS: "Don't show again",
    SHOW_INSTRUCTIONS: "Show again",
    ADMINISTRATION: "Admin Mode",
    ORGANIZATIONS: "Organizations",
    NEW_ORGANIZATION: "New Organization",
    EDIT_SUIT: "Edit Suit",
    SAVE: "Save",
    DELETE: "Delete",
    CANCEL: "cancelar",
    CONTINUE: "Seguir",
    ADD_SUIT: "Add Suit",
    NEW_SUIT_NAME: "NEW SUIT",
    NEW_SUIT_DEFINITION: "Please enter new suit definition",
    CONFIRM_DELETE_SUIT: "Confirm Delete Suit",
    DELETE_SUIT_MESSAGE: "Do you really want to delete the suit?",
    ADD_CARD: "Add Card",
    NEW_CARD_NAME: "NEW CARD",
    NEW_CARD_DEFINITION: "Please enter new card definition",
    CONFIRM_DELETE_CARD: "Confirm Delete Card",
    DELETE_CARD_MESSAGE: "Do you really want to delete the card?",
  }
};

const getResourceString = (lang, label) => {
  return ResourceStrings[lang][label];
};

export {getResourceString}
