import React, { useRef, useState, useEffect } from 'react';
import classnames from "classnames";
import { IconButton, Typography, Checkbox, Tooltip, Switch } from "@material-ui/core";
import { SaveOutlined, DeleteOutlined, CancelOutlined } from '@material-ui/icons';
import FlipIcon from '@material-ui/icons/Flip';
import FilterNoneOutlinedIcon from '@material-ui/icons/FilterNoneOutlined';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { CompactPicker } from "react-color";
import reactCSS from 'reactcss';

import Editable from "./utils/Editable";
import ConfirmDialog from './utils/ConfirmDialog';
import { getResourceString } from "./utils/Strings"
import "../../assets/stylesheets/cards.scss";

const defaultDeckImage = require("../../assets/images/card_deck_edit_EN.jpg");

const isSortCardMode = false;

const CapitalSuit = ({
    suit, lang, isAdminMode,
    handleFullscreenClick,
    handleSuitUpdateClick, handleSuitDeleteClick, handleSuitUploadClick,
    isSuitSelected, handleSelectSuit, isCardSelected, handleSelectCard
  }) => {

  const suitNameRef = useRef();
  const suitDefinitionRef = useRef();

  const nameField = lang === "EN" ? "name" : `name_${_.lowerCase(lang)}`;
  const defField = lang === "EN" ? "def" : `def_${_.lowerCase(lang)}`;

  const [ isFlipped, setIsFlipped ] = useState(false);
  const [ index, setIndex ] = useState(0);
  const [ suitName, setSuitName ] = useState();
  const [ suitDefinition, setSuitDefinition ] = useState();
  const [ suitImage, setSuitImage ] = useState();
  const [ isNameDefColorChanged, setIsNameDefColorChanged ] = useState(false);
  const [ isImageIconChanged, setIsImageIconChanged ] = useState(false);
  const [ imageIconMode, setImageIconMode ] = useState(false);
  const [ imageFile, setImageFile ] = useState();
  const [ iconFile, setIconFile ] = useState();
  const [ suitColor, setSuitColor ] = useState();
  const [ showColorPicker, setShowColorPicker ] = useState(false);

  useEffect(() => {
    setSuitName(_.get(suit, nameField));
    setSuitDefinition(_.get(suit, defField));
    setSuitImage(suit.image ? `data:image/jpeg;base64,${suit.image}` : defaultDeckImage);
    setSuitColor(_.get(suit, "color"));
  }, [lang, suit, isAdminMode]);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const handleSuitCardClick = () => {
    !isAdminMode && handleFlip();
  };

  const handleCardClick = () => {
    if (isSortCardMode) {
      if (isFlipped) {
        index === suit.cards.length-1 ? setIndex(0) : setIndex(index+1);
      }
    } else {
      !isAdminMode && handleFlip();
    }
  };

  const is_chrome = navigator.userAgent.indexOf("Chrome") > -1;
  const is_firefox = navigator.userAgent.indexOf("Firefox") > -1;
  const suitImageTransitionStyle = (is_chrome || is_firefox) ? "0.3s" : "0s";

  const handleEditSuit = () => {
    setIsFlipped(true);
  };

  const handleSuitUpdate = () => {
    // handle name and definition updates
    if (isNameDefColorChanged) {
      handleSuitUpdateClick(suit, nameField, suitName, defField, suitDefinition, suitColor);
      setIsNameDefColorChanged(false);
    }

    // handle image and icon file updates
    if (isImageIconChanged) {
      const formData = new FormData();
      formData.append("suit_id", suit.id)
      if (imageFile) {
        formData.append("image_file", imageFile)
      }
      if(iconFile) {
        formData.append("icon_file", iconFile)
      }
      handleSuitUploadClick(formData);
      setIsImageIconChanged(false);
    }

    setIsFlipped(!isFlipped);
  };

  const handleSuitDelete = () => {
    handleSuitDeleteClick(suit);
  };

  const handleSuitCancel = (suit) => {
    setIsFlipped(!isFlipped);
    setSuitName(_.get(suit, nameField));
    setSuitDefinition(_.get(suit, defField));
    setSuitColor(_.get(suit, "color"));
    setImageFile();
    setIconFile();
    setIsNameDefColorChanged(false);
  };

  const handleImageIconMode = () => {
    setImageIconMode(!imageIconMode);
  };

  const showEditable = () => {
    return (
      <div>
        <Editable
          className="card-label"
          text={suitName}
          placeholder="Suit Name"
          childRef={suitNameRef}
          type="input"
        >
        <input
          ref={suitNameRef}
          type="text"
          name="name"
          className="card-label"
          style={{padding: 0, margin: 0}}
          placeholder="Suit Name"
          value={suitName}
          onChange={e => {setSuitName(e.target.value); setIsNameDefColorChanged(true);}}
        />
        </Editable>
        <Editable
          className="card-definition"
          text={suitDefinition}
          placeholder="Suit Definition"
          childRef={suitDefinitionRef}
          type="textarea"
        >
          <textarea
            ref={suitDefinitionRef}
            style={{padding: 0, margin: 0, width: "100%", height: 176}}
            name="definition"
            placeholder="Suit Definition"
            value={suitDefinition}
            onChange={e => {setSuitDefinition(e.target.value); setIsNameDefColorChanged(true);}}
            />
        </Editable>
      </div>
    )
  };

  const handleImageFileChange = (file) => {
    setImageFile(file);
    setIsImageIconChanged(true);
  };

  const handleIconFileChange = (file) => {
    setIconFile(file);
    setIsImageIconChanged(true);
  };

  const handleColorChange = (color) => {
    setSuitColor(`${color.rgb.r},${color.rgb.g},${color.rgb.b}`);
    setIsNameDefColorChanged(true);
  };

  const styles = reactCSS({
    'default': {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `rgba(${suitColor})`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  const showImageIconColor = () => {
    return (
      <div className="form" style={{height: 237}}>
          <div style={{marginLeft: 10}}>
              <label style={{fontSize: 14}}>Suit Image File</label>
              <input type="file" name="image" style={{fontSize: 10, width: 150}} onChange={(e) => {handleImageFileChange(e.target.files[0])}}/>
              <br/>
              <br/>
              <label style={{fontSize: 14}}>Suit Icon File</label>
              <input type="file" name="icon" style={{fontSize: 10, width: 150}} onChange={(e) => {handleIconFileChange(e.target.files[0])}}/>
              <br/>
              <br/>
              <label style={{fontSize: 14}}>Suit Color</label>
              <div>
                <div style={styles.swatch} onClick={() => setShowColorPicker(true)}>
                  <div style={styles.color} />
                </div>
                {
                  showColorPicker && <div style={styles.popover}>
                    <div style={styles.cover} onClick={() => setShowColorPicker(false)}/>
                      <CompactPicker color={suitColor} onChange={handleColorChange} />
                    </div>
                }
              </div>
          </div>
      </div>
    )
  };

  const showSuitContent = (suit) => {
    return isAdminMode ? 
          <div style={{marginTop: 10}}>
            { !imageIconMode ? showEditable() : showImageIconColor() }
            <div className="icon-bar">
              <div style={{width: "100%", position: "absolute", top: "90%"}}>
                <Tooltip title={getResourceString(lang, "SAVE")} placement="start-top">
                  <IconButton disabled={!isImageIconChanged && !isNameDefColorChanged}
                    style={{float: "left", marginLeft: 10, marginTop: 6, fontSize: 40, padding: 0}}
                    onClick={handleSuitUpdate}
                  >
                    <SaveOutlined />
                  </IconButton>
                </Tooltip>
                <Tooltip title={getResourceString(lang, "DELETE")} placement="start-top">
                  <IconButton style={{float: "left", marginLeft: 5, marginTop: 6, fontSize: 40, padding: 0}} onClick={() => handleSuitDelete(suit)}>
                    <DeleteOutlined />
                  </IconButton>
                </Tooltip>
                <Tooltip title={getResourceString(lang, "CANCEL")} placement="start-top">
                  <IconButton style={{float: "right", marginRight: 10, marginTop: 6, fontSize: 40, padding: 0}} onClick={() => handleSuitCancel(suit)}>
                    <CancelOutlined />
                  </IconButton>
                </Tooltip>
                <Switch 
                  checked={imageIconMode}
                  onChange={handleImageIconMode}
                  color="default"
                  name="image-icon-mode"
                />
              </div>
            </div>
          </div>
        :
          <div style={{marginTop: 10}}>
            <Typography className="card-label">
              {_.get(suit, nameField)}
            </Typography>
            <Typography className="card-definition">
              {_.get(suit, defField)}
            </Typography>
          </div>
  };

  const showCardContent = (suit, index) => {
    return <div style={{marginTop: 10}}>
      <Typography className="card-label">
        {_.get(suit.cards[index], nameField)}
      </Typography>
      <Typography className="card-definition">
        {_.get(suit.cards[index], defField)}
      </Typography>
    </div>
  };

  const getSuitIcon = () => {
    return (`data:image/jpeg;base64,${suit.icon}`);
  };

  return (
    <div className={classnames("card-container", "card-container-main", {"is-flipped": isFlipped})}>
      {
        !isFlipped ?
          <div className={classnames("card", "card-face", "card-front-face")}
            style={{border: `2px solid rgb(${suit.color})`, boxShadow: `rgba(${suit.color}, 0.25) 4px 4px 1px 1px, rgba(${suit.color}, 0.2) 8px 8px 1px 1px, rgba(${suit.color}, 0.1) 12px 12px 1px 1px`}}
          >
            <img
              style={{transition: suitImageTransitionStyle, transform: lang === "ES" ? "rotate(180deg)" : "rotate(0deg)"}}
              src={suitImage} alt={`card_deck_${lang}`}
              onClick={handleSuitCardClick}
              draggable="false" />
            <div>
            {
              isSortCardMode && <FlipIcon className={classnames("flip-icon", {"is-flipped": isFlipped})} id="flip" onClick={handleFlip} />
            }
            {
              isSortCardMode && isFlipped ?
              (
                !_.isEmpty(suit.cards) &&
                  <Checkbox 
                    className="select-icon"
                    icon={<StarBorderIcon style={{color: "white"}}/>} checkedIcon={<StarIcon style={{color: "red"}}/>}
                    checked={isCardSelected(suit.cards[index])}
                    onChange={() => handleSelectCard(suit.cards[index])}
                  />
              ) :
              (
                !isFlipped && (
                  <div className="icon-bar">
                  {
                      !isAdminMode ? (
                        <Tooltip title={isSuitSelected(suit) ? getResourceString(lang, "UNSELECT_SUIT") : getResourceString(lang, "SELECT_SUIT")} placement="start-top">
                          <Checkbox 
                            className="select-icon"
                            icon={<StarBorderIcon style={{transform: "scale(1.2)", color: "white"}}/>} checkedIcon={<StarIcon style={{color: "red"}}/>}
                            checked={isSuitSelected(suit)}
                            onChange={() => handleSelectSuit(suit)}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title={getResourceString(lang, "EDIT_SUIT")} placement="start-top">
                            <Checkbox 
                              className="select-icon"
                              icon={<EditOutlinedIcon style={{transform: "scale(1.2)", color: "white"}}/>}
                              onClick={() => handleEditSuit(suit)}
                            />
                        </Tooltip>
                      )
                    }
                    <Tooltip title={getResourceString(lang, "OPEN_SUIT")} placement="start-top">
                      <FilterNoneOutlinedIcon style={{cursor: "pointer", color: "white", transform: "rotate(270deg)", height: "1.2rem", marginTop: "1.2rem", marginRight: "1rem"}} id="fullscreen" onClick={() => handleFullscreenClick(suit)} />
                    </Tooltip>
                  </div>
                )
              )
            }
            </div>
          </div> :
          <div className={classnames("card", "card-face", "card-back-face")}
            style={{border: `2px solid rgb(${suit.color})`, boxShadow: `rgba(${suit.color}, 0.25) 4px 4px 1px 1px, rgba(${suit.color}, 0.2) 8px 8px 1px 1px, rgba(${suit.color}, 0.1) 12px 12px 1px 1px`}}
            onClick={handleCardClick}
          >
          {
            // show suit definition if not in sort card mode
            !isSortCardMode ?
            (
              showSuitContent(suit)
            ) :
            (
              showCardContent(suit, index)
            )
          }
          </div>
      }
      {
        isFlipped && !isSortCardMode && !isAdminMode &&
          <div className="icon-bar">
            <div style={{width: 60}}>
              <img src={getSuitIcon()}  className="suit-icon" draggable="false" />
            </div>
          </div>
      }
    </div>
  )
};

export default CapitalSuit;