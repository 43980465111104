import React, { useState } from 'react';
import {Avatar, Button, Container, TextField, Typography} from '@material-ui/core';
import {FormControl, InputLabel} from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import CreatableSelect from 'react-select/creatable';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  joinGame: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
    backgroundColor: "#63544a",
    fontWeight: "bold",
    '&:hover': {
      color: '#63544a',
    }
  },
}));

const CardAccessForm = ({handleSetAllowAccess, handleJoinGame, organizations}) => {
  const [ firstName, setFirstName ] = useState();
  const [ lastName, setLastName ] = useState();
  const [ email, setEmail ] = useState();
  const [ organization, setOrganization ] = useState();
  const [ accessCode, setAccessCode ] = useState();
  const [ invalidAccessCodeEntered, setinvalidAccessCodeEntered ] = useState(false);
  const [ accessCodeLimitExceeded, setAccessCodeLimitExceeded ] = useState(false);
  const [ showAccessCode, setShowAccessCode ] = useState(false);

  const classes = useStyles();

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  }

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handleOrganizationChange = (event) => {
    setOrganization(event);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && firstName && lastName && email && accessCode) {
      handleCheckAccessCode();
    }
  }
  const handleAccessCodeChange = (event) => {
    setAccessCode(event.target.value);
    if (invalidAccessCodeEntered) {
      setinvalidAccessCodeEntered(false);
    }  
  }

  const getDefaultAccessCode = () => {
    const defaultOrganization = _.find(organizations, {id: 1}); // BII default Org
    return _.get(defaultOrganization, "access_code");
  };

  const isAdminAccessCode = (accessCode) => {
      const adminOrganization = _.find(organizations, {id: 0});  // BII Admin Org
      return accessCode && _.get(adminOrganization, "access_code") === accessCode;
  };

  const handleCheckAccessCode = async () => {
    // organization can be either:
    // 1) empty - organzation === undefined or administration
    // 2) manually entered - organization only has label and value (id) fields
    // 3) chosen from list - organization has id, label, value and access_code fields
    const validOrgAccessCode = _.get(organization, "access_code", getDefaultAccessCode());
    const isAdminUser = isAdminAccessCode(accessCode);
    if (accessCode === validOrgAccessCode || isAdminUser) {
      const success = await handleJoinGame(firstName, lastName, email, organization, accessCode);
      if (success) {
        handleSetAllowAccess(isAdminUser);
      } else {
        // show limit has exceeded dialog
        setAccessCodeLimitExceeded(true);
      }
    } else {
      setinvalidAccessCodeEntered(true);
    }
  }

  const getOrganizationItems = () => {
    const items = [];
    _.map(organizations, organization => {
      // add organization to array excluding Administration and Boston Impact Initiative
      if (!organization.disabled && organization.id !== 0 && organization.id !== 1) {
        items.push({value: organization.id, label: organization.name, id: organization.id, name: organization.name, access_code: organization.access_code});
      }
    });
    return items;
  };

  const handleShowAccessCode = () => {
    setShowAccessCode(!showAccessCode);
  };
  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="first-name"
            label="First Name"
            name="first_name"
            autoFocus
            onChange={(e) => handleFirstNameChange(e)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="last-name"
            label="Last Name"
            name="last_name"
            autoFocus
            onChange={(e) => handleLastNameChange(e)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoFocus
            onChange={(e) => handleEmailChange(e)}
          />
          <div style={{marginTop: 16, marginBottom: 24}}>
            <CreatableSelect
              placeholder={"Select or enter organization, then hit return key"}
              menuPortalTarget={document.body}
              styles={{ control: base => ({...base, height: 48, minHeight: 48}), menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              onChange={(e) => handleOrganizationChange(e)}
              options={getOrganizationItems()}
            />
          </div>
          <FormControl variant="outlined" className={classes.formControl} fullWidth>
            <InputLabel htmlFor="outlined-adornment-access-code">Access Code</InputLabel>
            <OutlinedInput
              id="outlined-adornment-access-code"
              type={showAccessCode ? 'text' : 'password'}
              value={accessCode}
              onChange={handleAccessCodeChange}
              onKeyDown={handleKeyDown}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                  style={{ border:"none", outline:"none"}}
                    aria-label="toggle access code visibility"
                    onClick={handleShowAccessCode}
                    edge="end"
                  >
                    {showAccessCode ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Access Code"
            />
          </FormControl>
          <Typography variant="body2" style={{color: "red"}} align="center">
            {invalidAccessCodeEntered && "You've entered an invalid access code.  Please try again."}
          </Typography>
          <Typography variant="body2" style={{color: "red"}} align="center">
            {accessCodeLimitExceeded && "Maximum number of players allowed to join game has been reached.  Please contact us."}
          </Typography>
          <Button
            fullWidth
            variant="contained"
            className={classes.joinGame}
            disabled={!firstName || !lastName || !email || !accessCode}
            onClick={handleCheckAccessCode}
          >
            Join Game
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default CardAccessForm;