

import React, {useState} from 'react';

import { ExpandMore } from '@material-ui/icons';
import { Button, TextField, Accordion, AccordionSummary, AccordionDetails, Tooltip, Typography, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircleOutline } from '@material-ui/icons';
import FormControlLabel from "@material-ui/core/FormControlLabel";

import axios from "axios";
import _ from "lodash";

import { getResourceString } from "./utils/Strings"
import PlayerList from "./PlayerList";

const canResetSeats = true;

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    button: {
      width: "40%",
      margin: "8px",
      color: "white",
      backgroundColor: "#63544a",
      fontWeight: "bold",
      '&:hover': {
        color: '#63544a',
      }
    },
}));
  
const Organizations = ({lang, orgs}) => {
    const [organizations, setOrganizations] = useState(orgs)
    const [showOrganizationName, setShowOrganizationName] = useState(false)
    const [expandedOrganization, setExpandedOrganization] = useState();
    const [playerListOpen, setPlayerListOpen] = useState(false);
    const [players, setPlayers] = useState([]);
  
  
    const classes = useStyles();
    
    if (_.isEmpty(organizations)) {
        return null;
    }

    const handleOpenPlayerList = async (organization) => {
        setPlayers(await fetchPlayers(organization));
        setShowOrganizationName(organization.id === 1); // BII organization
        setPlayerListOpen(true);
    };

    const handleClosePlayerList = () => {
        setShowOrganizationName(false);
        setPlayerListOpen(false);
    };
    
    const fetchPlayers = async (organization) => {
        const url = `/api/players/fetch?organization_id=${organization.id}`;
        return _.get(await axios.get(url), "data", []);
    };

    const handleExpandOrganization = (organization) => (event, isExpanded) => {
        setExpandedOrganization(isExpanded ? organization : null);
    };

    const handleSaveOrganization = async () => {
        const org = _.find(organizations, {id: expandedOrganization.id});
        const url = `/api/organizations/${org.isNew ? "create" : "update"}`;
        const payload =_.get(await axios.post(url, {
            id: org.id,
            name: org.name,
            seats: org.seats,
            player_count: org.player_count,
            disabled: org.disabled,
            access_code: org.access_code
        }), "data");

        // set save organization to be not new anymore
        const orgs = _.cloneDeep(organizations);
        const index = _.findIndex(organizations, {id: expandedOrganization.id});
        if (index > -1) {
            orgs[index] = payload;
            setOrganizations(orgs);
        }

        // close organization item
        setExpandedOrganization;
    };
    
    const handleCancel = () => {
        const orgs = _.cloneDeep(organizations);
        const index = _.findIndex(organizations, {id: expandedOrganization.id});
        if (index > -1) {
            if (expandedOrganization.isNew) {
                // remove new organization that has not been saved
                orgs.splice(index, 1);
            } else {
                orgs[index] = expandedOrganization;
            }
            setOrganizations(orgs);
        }

        // close organization item
        setExpandedOrganization();
    };
    
    const handleNewOrganization = () => {
        const newId = Math.max.apply(Math, organizations.map((o) => { return o.id; })) + 1;
        const newOrg = {id: newId, name: "Untitled", seats: 1, player_count: 0, access_code: "", isNew: true}
        organizations.push(newOrg);
        setOrganizations(organizations);
        setExpandedOrganization(newOrg);
    };

    const onChange = (event) => {
        const orgs = _.cloneDeep(organizations);
        const org = _.find(orgs, {id: expandedOrganization.id});
        const field = event.target.name;
        const type = event.target.type;
        org[field] = type === "checkbox" ? event.target.checked : event.target.value;
        setOrganizations(orgs);
    };

    return <div>
        <Tooltip title={getResourceString(lang, "NEW_ORGANIZATION")} placement="start-top">
            <AddCircleOutline
            style={{float: "right", marginTop: 5, marginRight: 15, fontSize: 28, cursor: "pointer"}}
            id="fullscreen"
            onClick={() => handleNewOrganization()}
            />
        </Tooltip>
        <Typography variant="h6" style={{marginTop: 4, textAlign: "center", color: "black"}}>
            {getResourceString(lang, "ORGANIZATIONS")}
        </Typography>
        {
            organizations.map((organization) => {
                return <Accordion expanded={_.get(expandedOrganization, "id") === organization.id} onChange={handleExpandOrganization(organization)}>
                    <AccordionSummary  expandIcon={<ExpandMore />}>
                        {organization.name}
                    </AccordionSummary>
                    <AccordionDetails>
                        <form className={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                name="name"
                                value={organization.name}
                                onChange={(e) => onChange(e)}
                            />
                            <div className="flex">
                                <TextField
                                    className="flex"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    style={{width: 85}}
                                    id="seats"
                                    label="Seats"
                                    name="seats"
                                    autoFocus
                                    value={organization.seats}
                                    onChange={(e) => onChange(e)}
                                />
                                <TextField
                                    className="flex"
                                    variant="outlined"
                                    margin="normal"
                                    style={{width: 85, marginLeft: 10}}
                                    id="player_count"
                                    label="Used"
                                    name="player_count"
                                    value={organization.player_count}
                                    onChange={(e) => onChange(e)}
                                    inputProps={
                                        { readOnly: canResetSeats }
                                    }
                                />
                            </div>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="access-code"
                                label="Access Code"
                                name="access_code"
                                value={organization.access_code}
                                onChange={(e) => onChange(e)}
                            />
                            <div style={{display: "flex", alignItems: "center"}}>
                                <FormControlLabel
                                style={{padding: 0, margin: "0 10px 0 -10px"}}
                                    control={<Checkbox color="primary" id="disabled" name="disabled" checked={organization.disabled} onChange={(e) => onChange(e)} />}
                                    label="Disabled"
                                />
                                <Typography style={{textDecoration: "underline", cursor: "pointer", color: "#3f51b5"}} onClick={() => handleOpenPlayerList(organization)}>Players</Typography>
                            </div>
                            <Button
                                variant="contained"
                                className={classes.button}
                                onClick={() => handleSaveOrganization()}
                            >
                                {organization.isNew ? "Add" : "Update"}
                            </Button>
                            <Button
                                variant="contained"
                                className={classes.button}
                                onClick={(e) => handleCancel()}
                            >
                                Cancel
                            </Button>
                        </form>
                    </AccordionDetails>
                </Accordion>
            })
        }
        <PlayerList players={players} isOpen={playerListOpen} handleClose={handleClosePlayerList} showOrganizationName={showOrganizationName}/>
</div>
}


export default Organizations;