import React from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    IconButton,
    Typography,
  } from '@material-ui/core';
  import { Close } from '@material-ui/icons';
  

const ConfirmDialog = ({dialogId, title, cancelLabel, confirmLabel, message, onSubmit, open, close, classes, item}) => {
   return (
     <Dialog open={open(dialogId)} onClose={() => close(dialogId)} maxWidth="sm" fullWidth> 
       <DialogTitle>{title}</DialogTitle>
       <Box position="absolute" top={0} right={0}>
         <IconButton onClick={close}>
           <Close />
         </IconButton>
       </Box>
       <DialogContent>
         <Typography>{message}</Typography>
       </DialogContent>
       <DialogActions>
         <Button variant="outlined" onClick={close}>
            {cancelLabel}
         </Button>
         <Button
            className={classes.button} 
           variant="contained"
           onClick={() => {
             if (onSubmit) {
               onSubmit(item);
             }
             close();
           }}
         >
           {confirmLabel}
         </Button>
       </DialogActions>
     </Dialog>
   );
 };

 export default ConfirmDialog;